import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import themeUtils from '../lib/themeUtils';
import { Layout, Seo } from '../components/compounds';
import {
  Section,
  Container,
  Title,
  Paragraph,
  Row,
  Col,
  Link,
  List,
} from '../components/blocks';
import { hideOnDesktopCss, hideOnMobileCss } from '../lib/style/responsive';
import { mediaMinWidthMixins } from '../lib/style/mixins/media';
import { withAnalyticsOutboundClick } from '../components/hoc';
const { getTheme } = themeUtils;

const RowVerticalCentered = styled(Row)`
  align-items: center;
`;
const ColHalf = styled(Col)`
  ${themeUtils.mq({
    width: ['100%', null, null, '50%'],
  })}
`;

// sends analytics
const OutboundLink = withAnalyticsOutboundClick(Link);
const EmphasizedOutboundLink = styled(OutboundLink)`
  font-family: industry;
  font-size: ${themeUtils.fontSizes.xxs};
  font-weight: 700;
  letter-spacing: 3px;
  margin-top: 0;
  ${themeUtils.mq({
    display: ['block', null, null, 'inline-block'],
    marginBottom: [themeUtils.spacings.sm, null, null, '0'],
  })}
`;

const HorizontalSeparator = styled.span`
  ${themeUtils.mq({
    display: ['none', null, null, 'inline-block'],
  })}
  margin-left: 15px;
  margin-right: 15px;
`;

const SectionVariant = styled(Section)`
  background-color: ${p => getTheme(p).colors.bg1};
  overflow: hidden;
`;

const LaptopWrapper = styled.div`
  max-width: 900px;
  position: relative;
`;
const LaptopWrapperRight = styled(LaptopWrapper)`
  margin-left: auto;
`;
const LaptopWrapperLeft = styled(LaptopWrapper)`
  margin-right: auto;
`;

const getParagraphCss = side => () => {
  const property = side === 'left' ? 'paddingRight' : 'paddingLeft';
  return mediaMinWidthMixins.lg(
    css({
      [property]: themeUtils.spacings.lg,
    })
  );
};

const WrapperDiv = Paragraph.withComponent('div');

const ProposalPage = ({ data }) => (
  <Layout>
    <Seo title="Proposal" />
    <Section>
      <Container>
        <Row>
          <ColHalf>
            <Title>YESOD RELEASE CANDIDATE 1 - MERGED</Title>
            <Paragraph
              css={css`
                margin-bottom: 0;
              `}
            >
              A potential upgrade to Zen Protocol, “Yesod-RC1” git ID{' '}
              <OutboundLink
                target="_blank"
                title="05d38f0f9a2aae6cd52cd0650d4880461dabc936"
                href="https://gitlab.com/blockchaindevelopment/zenprotocol/commit/05d38f0f9a2aae6cd52cd0650d4880461dabc936"
              >
                05d38f...abc936
              </OutboundLink>
              , is now available for community review. The most recent Zen
              Protocol Upgrade provides token holders with an improved
              governance toolset, thus it is fitting that this proposal provides
              an improved toolset to execute decisions that are made. This
              upgrade provides coin holders with 2 primary abilities:
            </Paragraph>
            <WrapperDiv>
              <List ordered>
                <li>
                  The ability to divide the block rewards between miners and the{' '}
                  <OutboundLink
                    target="_blank"
                    title="CgpContract"
                    href="https://github.com/zenprotocol/contracts/blob/master/CGP/CGP.fst"
                  >
                    CgpContract
                  </OutboundLink>
                </li>
                <li>
                  The ability to determine where the funds which are held in
                  custody by the CgpContract will be sent to.
                </li>
              </List>
            </WrapperDiv>
          </ColHalf>
          <ColHalf />
        </Row>
        <Row>
          <Col>
            <div>
              <EmphasizedOutboundLink
                target="_blank"
                decoration={true}
                href="https://gitlab.com/blockchaindevelopment/zenprotocol/blob/release-candidate-1/CHANGES.md"
              >
                READ TECHNICAL PAPER
              </EmphasizedOutboundLink>
              <HorizontalSeparator>|</HorizontalSeparator>
              <EmphasizedOutboundLink
                target="_blank"
                decoration={true}
                href="https://gitlab.com/blockchaindevelopment/zenprotocol/commit/05d38f0f9a2aae6cd52cd0650d4880461dabc936"
              >
                GITLAB
              </EmphasizedOutboundLink>
              <HorizontalSeparator>|</HorizontalSeparator>
              <EmphasizedOutboundLink
                target="_blank"
                decoration={true}
                href="https://medium.com/@blockchaindevelopmentltd/yesod-release-candidate-85389f05c0b6"
              >
                BLOG POST
              </EmphasizedOutboundLink>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
    <SectionVariant>
      <Container>
        <RowVerticalCentered>
          <ColHalf css={getParagraphCss('left')}>
            <Title>NOMINATION PHASE</Title>
            <Paragraph>
              The first phase, a period of 500 blocks, allows ZP holders to
              nominate their preferred proposal’s PayoutBallot ID. Only ballots
              which have been nominated by at least 3% of outstanding ZP will be
              eligible recipients of a PayoutTransaction in the vote phase.
            </Paragraph>
          </ColHalf>
          <ColHalf>
            <LaptopWrapperRight>
              <Img fluid={data.nominationPhase.childImageSharp.fluid} />
            </LaptopWrapperRight>
          </ColHalf>
        </RowVerticalCentered>
      </Container>
    </SectionVariant>
    <SectionVariant>
      <Container>
        <RowVerticalCentered>
          <ColHalf css={hideOnMobileCss}>
            <LaptopWrapperLeft>
              <Img fluid={data.votingPhase.childImageSharp.fluid} />
            </LaptopWrapperLeft>
          </ColHalf>
          <ColHalf css={getParagraphCss('right')}>
            <Title>VOTING PHASE</Title>
            <Paragraph
              css={css`
                margin-bottom: 0;
              `}
            >
              The second phase, a period of 500 blocks, allows the token holders
              to cast a vote on:
            </Paragraph>
            <WrapperDiv>
              <List ordered>
                <li>
                  The division of ZP created in a coinbase transaction between
                  Miners and the CgpContract. In order for a block to be valid,
                  it must include an AllocationOutput of X to the CgpContract.
                  Each month/vote this changes, based on the weighted median
                  result of the ZP that vote. In order to provide stability to
                  miners we guarantee that the maximum variance of the block
                  rewards will be 15% from one vote period to the next.
                </li>
                <li>
                  The winning PayoutBallot (recipient, assets & amounts) is
                  calculated using a simple first-past-the-post system. Each
                  interval (~month) there is exactly one winner. Users can vote
                  to abstain by setting the CgpContract as the PayoutRecipient.
                  We encourage developers to create smart contracts to enable
                  more complex payment schemes.
                </li>
              </List>
            </WrapperDiv>
          </ColHalf>
          <ColHalf css={hideOnDesktopCss}>
            <LaptopWrapperLeft>
              <Img fluid={data.votingPhase.childImageSharp.fluid} />
            </LaptopWrapperLeft>
          </ColHalf>
        </RowVerticalCentered>
      </Container>
    </SectionVariant>
  </Layout>
);
ProposalPage.propTypes = {
  data: PropTypes.object,
};

export default ProposalPage;

export const imageShapeProposalLaptop = graphql`
  fragment imageShapeProposalLaptop on File {
    childImageSharp {
      fluid(maxWidth: 942, quality: 80) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;
export const query = graphql`
  query {
    nominationPhase: file(relativePath: { regex: "/RC1_Nomination_Phase/" }) {
      ...imageShapeProposalLaptop
    }

    votingPhase: file(relativePath: { regex: "/RC1_Voting_Phase/" }) {
      ...imageShapeProposalLaptop
    }
  }
`;
