import { css } from '@emotion/core';
import themeUtils from '../themeUtils';

export const hideOnMobileCss = css`
  ${themeUtils.mq({
    display: ['none', null, null, 'block'],
  })}
`;
export const hideOnDesktopCss = css`
  ${themeUtils.mq({
    display: ['block', null, null, 'none'],
  })}
`;
